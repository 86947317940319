html {
  scroll-behavior: smooth;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto;
  /* font-family: Arial, Helvetica, sans-serif; */
  /* font-size: 10pt; */
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.css-16pqwjk-indicatorContainer{
  padding: 0 !important
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: none;
}

.full__calendar {
  width: -webkit-fill-available;
}

.rbc-toolbar {
  padding: 0 10px !important;
}

.rbc-toolbar-label {
  padding: 8px 16px !important;
}

.mbsc-scv-liq .mbsc-scv-item {
  flex-direction: row;
  align-items: center;
}

.mbsc-ms-bottom.mbsc-ms-c {
  z-index: 999;
}

.mbsc-ios.mbsc-btn-light.mbsc-btn {
  color: #007bff;
}

.mbsc-label {
  width: 24% !important;
}

.deal__form .mbsc-label{
  width: 32% !important;
  padding-left: 0 !important;
}

.deal__form Label{
  padding-left: 16px !important;
}

.longLabel > span.mbsc-label {
  width: auto !important;
}

.mbsc-btn,
.mbsc-control-w,
.mbsc-form,
.mbsc-segmented {
  font-family: Inconsolata, monospace !important;
}
.mbsc-ios .mbsc-ltr .mbsc-cal-event-color {
  left: 5px;
  top: 15px;
  bottom: 10px;
  height: calc(100% - 20px);
}
.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-txt {
  height: auto;
  /* height: 40px; */
  max-height: 50px;
}
